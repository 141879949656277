.Join{
    pad: 0 2rem;
    gap: 10rem;
}

.left-j{
color: white;
font-size: 3rem;
font-weight: bold;
text-transform: uppercase;
position: relative;
left: 2rem;
}
.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 50%;
    margin: -15px 0; 
}
.left-j>div{
    display: flex;
    gap: 2rem;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container{
    display: flex;
    gap: 2rem;
 background-color: gray;
margin-top: 3rem; 
margin-left: 1rem; 
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
    padding-bottom:1rem;
    padding-top: 1rem;
    margin-left: 1rem;
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    
    background-color: var(--orange);
    color: white;
margin-right: 1rem;
margin-bottom: 1rem;
}


@media screen and(max-width: 768px){
    .Join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
font-size: x-large;
flex-direction: column;
    }
    .right-j{
        padding: 2rem;
    }

    
}